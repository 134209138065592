<template>
  <div class="login__box">
    <div class="login__wrapper">
      <div class="login__header">Вхід в аккаунт</div>
      <form class="login__form" @submit.prevent="login">
        <label
          class="field__label login__label"
          :class="{
            'field__label--filled': $v.email.required && $v.email.email,
            'field__label--invalid':
              ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email) || loginError,
            'animate__shakeX animate__animated': loginError
          }"
        >
          <div class="field__title field__title">Введіть Логін</div>
          <input class="field__input" v-model="email" placeholder="E-mail" type="email" autocomplete="username" />
        </label>
        <label
          class="field__label login__label"
          :class="{
            'field__label--filled': $v.password.required && $v.password.minLength,
            'field__label--invalid':
              ($v.password.$dirty && !$v.password.required) ||
              ($v.password.$dirty && !$v.password.minLength) ||
              loginError,
            'animate__shakeX animate__animated': loginError
          }"
        >
          <div class="field__title field__title">Введіть Пароль</div>
          <input
            v-model="password"
            placeholder="Пароль"
            class="field__input"
            type="password"
            autocomplete="current-password"
          />
        </label>
        <button type="submit" class="button button--fill login__button">
          Увійти
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import {required, email, minLength} from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      email: '',
      password: '',
      loginError: false
    }
  },
  computed: {
    error() {
      return this.$store.getters.error
    }
  },
  watch: {
    error() {
      const error = this.error
      if (error) {
        if (error.response.status == 500 || error.response.status == 400) {
          this.loginError = true
        }
      }
      setTimeout(() => {
        this.loginError = false
      }, 2000)
    }
  },
  methods: {
    async login() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      let email = this.email
      let password = this.password
      await this.$store.dispatch('login', {email, password})
      this.$router.push('/')
    }
  },
  validations: {
    email: {required, email},
    password: {required, minLength: minLength(8)}
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
