// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/icons/login.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login__box[data-v-aeba673c] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.login__wrapper[data-v-aeba673c] {\n  background-color: #1C2027;\n  border: 1px solid #282F39;\n  padding: 40px;\n  border-radius: 6px;\n  width: 500px;\n}\n.login__header[data-v-aeba673c] {\n  font-size: 20px;\n  color: #fff;\n  margin-bottom: 30px;\n}\n.login__button[data-v-aeba673c] {\n  width: 100%;\n}\n.login__label[data-v-aeba673c] {\n  margin-bottom: 30px;\n}\n@media only screen and (max-width: 1024px) {\n.login__box[data-v-aeba673c] {\n    height: -webkit-fill-available;\n    height: -moz-available;\n    height: stretch;\n}\n}", ""]);
// Exports
module.exports = exports;
