var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login__box" }, [
    _c("div", { staticClass: "login__wrapper" }, [
      _c("div", { staticClass: "login__header" }, [_vm._v("Вхід в аккаунт")]),
      _c(
        "form",
        {
          staticClass: "login__form",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.login($event)
            }
          }
        },
        [
          _c(
            "label",
            {
              staticClass: "field__label login__label",
              class: {
                "field__label--filled":
                  _vm.$v.email.required && _vm.$v.email.email,
                "field__label--invalid":
                  (_vm.$v.email.$dirty && !_vm.$v.email.required) ||
                  (_vm.$v.email.$dirty && !_vm.$v.email.email) ||
                  _vm.loginError,
                "animate__shakeX animate__animated": _vm.loginError
              }
            },
            [
              _c("div", { staticClass: "field__title field__title" }, [
                _vm._v("Введіть Логін")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email"
                  }
                ],
                staticClass: "field__input",
                attrs: {
                  placeholder: "E-mail",
                  type: "email",
                  autocomplete: "username"
                },
                domProps: { value: _vm.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value
                  }
                }
              })
            ]
          ),
          _c(
            "label",
            {
              staticClass: "field__label login__label",
              class: {
                "field__label--filled":
                  _vm.$v.password.required && _vm.$v.password.minLength,
                "field__label--invalid":
                  (_vm.$v.password.$dirty && !_vm.$v.password.required) ||
                  (_vm.$v.password.$dirty && !_vm.$v.password.minLength) ||
                  _vm.loginError,
                "animate__shakeX animate__animated": _vm.loginError
              }
            },
            [
              _c("div", { staticClass: "field__title field__title" }, [
                _vm._v("Введіть Пароль")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password"
                  }
                ],
                staticClass: "field__input",
                attrs: {
                  placeholder: "Пароль",
                  type: "password",
                  autocomplete: "current-password"
                },
                domProps: { value: _vm.password },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.password = $event.target.value
                  }
                }
              })
            ]
          ),
          _c(
            "button",
            {
              staticClass: "button button--fill login__button",
              attrs: { type: "submit" }
            },
            [_vm._v(" Увійти ")]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }